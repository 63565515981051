import './App.scss';
import Header from './component/header';
import Body from './component/body';
import Footer from './component/footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
